.slider {
  
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  
  }
  .image_wrapper{
   
    display: flex !important;
   align-items: flex-start;
   justify-content: flex-start;
 margin-right: 10px;
    margin-bottom: 10px;
    width: 500px;
  }
  
  
  .image1{
    width: 192px;
    height: 240px;  
    object-fit: cover;
    margin-right: 10px;
  }
  .image2{
    width: 150px;
    height: 130px;
    object-fit: cover;
   margin-right: 10px;
  }
  .image3
  {
    width: 172px;
    height: 90px;
    object-fit: cover;
   margin-right: 10px;

   
  }
  .wrapperclass{
    display: flex;
    background-color: #EEEBEB;
    width: 540px;
    align-items: center;
    justify-content: center;
  }
  .right-arrow {
    
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
   
  }
  
  .slide.active {
    opacity: 1;
   
  }
  .bottomIconBar{
    display: flex;
    align-items: center;
    justify-content: center;
    
    
  }